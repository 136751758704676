html {
  scroll-snap-type: y mandatory;
}

*,
html,
body {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  color: #F6FEFF;
}

@font-face {
  font-family: "GillSans";
  src:
    local('GillSans')
    url('./assets/fonts/GillSans.ttc') format("ttc"),
}

@font-face {
  font-family: "Copperplate";
  src:
    local('Copperplate')
    url('./assets/fonts/Copperplate.ttc') format("ttc"),
}

@font-face {
  font-family: "Futura";
  src:
    local("Futura")
    url('./assets/fonts/Futura.ttc') format("ttc"),
}
